class UserVO {
	constructor (raw) {
		this.id = null
		this.type = ''

		this.avatarUrl = null
		this.email = null
		this.lastLoginDate = null
		this.locale = null
		this.name = null
		this.pointsEarned = null
		this.roles = []
		this.state = null
		this.timeZoneCode = null
		this.userType = null
		this.pointsEarned = 0

		if (raw) {
			this.id = raw.id
			this.type = raw.type

			for (const prop in raw.attributes) {
				this[prop] = raw.attributes[prop]
			}
		}
	}

	get initials () {
		return this.name ? this.name.split(' ').map(i => i[0]).join('') : ''
	}
}

export default UserVO
