class SlideVO {
  constructor(raw) {
    this.header = null;
    this.description = null;
    this.imageUrl = null;
    this.primaryActionUrl = null;
    this.secondaryActionUrl = null;
    this.primaryButtonLabel = null;
    this.secondaryButtonLabel = null;
    this.primaryActionIsCourse = false;
    this.secondaryActionIsCourse = false;
    this.order = 0;

    if (raw) {
      for (let prop in raw) {
        this[prop] = raw[prop];
      }
    }
  }
}

export default SlideVO;