class LearningVO {
  constructor( raw ) {
    this.id = null;
    this.type = null;

    this.authorNames = null;
    this.dateCreated = null;
    this.datePublished = null;
    this.dateUpdated = null;
    this.effectiveModifiedDate = null;
    this.effectivenessIndex = null;
    this.enrollmentType = null;
    this.isExternal = false;
    this.isPrerequisiteEnforced = false;
    this.isSubLoOrderEnforced = false;
    this.loType = null;
    this.moduleResetEnabled = null;
    this.state = null;

    this.description = null;
    this.locale = null;
    this.name = null;
    this.overview = null;

		this.imageUrl = null;

    this.enrollmentId = null;
    this.instanceId = null;

    if( raw.attributes ) {
      this.id = raw.id;
      this.type = raw.type;

      for( let prop in raw.attributes ) {
        if( prop !== "localizedMetadata" ) {
          this[prop] = raw.attributes[prop];
        } else {
          for( let prop in raw.attributes.localizedMetadata[0] ) {
            this[prop] = raw.attributes.localizedMetadata[0][prop];
          }
        }

      }
    }
    if( raw.relationships ) {
      this.enrollmentId = raw.relationships.enrollment ? raw.relationships.enrollment.data.id :  null;
      this.instanceId = raw.relationships.instances.data[0].id;
    }

    this.completionDeadline = null;
    this.dateCompleted = null;
    this.dateEnrolled = null;
    this.dateStarted = null;
    this.hasPassed = false;
    this.progressPercent = null;
    this.score = null;
    this.enrollmentState = null;

    this.hasDeadline = false;
  }

  updateEnrollment( raw ) {
    if( raw && raw.attributes ) {
      this.dateCompleted = raw.attributes.dateCompleted;
      this.dateEnrolled = raw.attributes.dateEnrolled;
      this.dateStarted = raw.attributes.dateStarted;
      this.hasPassed = raw.attributes.hasPassed;
      this.progressPercent = raw.attributes.progressPercent;
      this.score = raw.attributes.score;
      this.enrollmentState = raw.attributes.state;
    }
  }

  updateLearnerObjectInstance( raw ) {
    if( raw && raw.attributes ) {
      if (raw.attributes.completionDeadline && raw.attributes.dateCreated) {
        this.hasDeadline = !!raw.attributes.completionDeadline;//(new Date(raw.attributes.completionDeadline).getFullYear() - new Date(raw.attributes.dateCreated).getFullYear()) < 10;
      }
      this.completionDeadline = raw.attributes.completionDeadline;
    }
  }
}

export default LearningVO;

