import Vue from 'vue';
import App from './App.vue';
import store from './store/store';
import router from './router';
import BootstrapVue from 'bootstrap-vue';
import clampy from '@clampy-js/vue-clampy';
import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(clampy);

Vue.filter('formatDate', function (d) {
	const date = new Date(d);
	const months = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'June', 'July', 'Aug.', 'Sept.', 'Oct.', 'Nov.', 'Dec.'];
	return months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
});

axios.get('config.json').then(data => {
	store.commit('setAccountId', data.data.accountId);
	store.commit('setClientId', data.data.clientId);
	store.commit('setGateway', data.data.gateway);
	store.commit('setRequiredCatalogId', data.data.requiredCatalogId);

	new Vue({
		router,
		store,
		render: h => h(App)
	}).$mount('#app');
});
