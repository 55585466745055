class NotificationVO {
	constructor (raw) {
		this.id = null;
		this.type = '';

		this.actionTaken = false;
		this.channel = null;
		this.dateCreated = null;
		this.message = null;
		this.modelIds = null;
		this.modelNames = null;
		this.modelTypes = null;
		this.read = false;
		this.role = null;

		if (raw) {
			this.id = raw.id;
			this.type = raw.type;

			for (const prop in raw.attributes) {
				this[prop] = raw.attributes[prop];
			}
		}
	}

	get text () {
		let m = this.message;
		if (this.modelNames.length) {
			m = m.replace('{{{name0}}}', this.modelNames[0]);
			if (this.modelNames.length > 1) { return m.replace('{{{name1}}}', `<b>${this.modelNames[1]}</b>`); }
		}
		return m;
	}

	get title () {
		return this.message.replace('{{{name0}}}', '');
	}

	get link () {
		return this.modelNames.length ? this.modelNames[0] : '';
	}

	get time () {
		let diff = new Date().getTime() - new Date(this.dateCreated).getTime();
		diff /= 1000;
		let v = 0;
		if (diff / (60 * 60 * 24 * 30) > 1) {
			v = Math.round(diff / (60 * 60 * 24 * 30));
			v = `${v} month`;
		} else if (diff / (60 * 60 * 24) > 1) {
			v = Math.round(diff / (60 * 60 * 24));
			v = `${v} day`;
		} else if (diff / (60 * 60) > 1) {
			v = Math.round(diff / (60 * 60));
			v = `${v} hour`;
		} else if (diff / (60) > 1) {
			v = Math.round(diff / (60));
			v = `${v} minute`;
		}
		v += (v === 1 ? ' ago' : 's ago');
		return v;
	}
}

export default NotificationVO;
