class FranchiseVO {
	constructor (raw) {
		this.id = null;
		this.franchise = null;
		this.role = null;
		this.adminAccessGroups = [];

		if (raw) {
			for (const prop in raw) {
				this[prop] = raw[prop];
			}
		}
	}
}

export default FranchiseVO;
