import PrimeAuthVO from '../vo/PrimeAuthVO';
import UserVO from '../vo/UserVO';
import NotificationVO from '../vo/NotificationVO';
import LearningVO from '../vo/LearningVO';
import StatVO from '../vo/StatVO';
import BrandVO from '../vo/BrandVO';
import SkillVO from '../vo/SkillVO';
import RecommendedVO from '../vo/RecommendedVO';
import SlideVO from '../vo/SlideVO';
import DisclaimerVO from '../vo/DisclamerVO';
import FranchiseVO from '../vo/FranchiseVO';

export const setAccountId = (state, value) => {
	state.accountId = value;
};
export const setGateway = (state, value) => {
	state.gateway = value;
};
export const setRequiredCatalogId = (state, value) => {
	state.requiredCatalogId = value;
};
export const setPrimeAuth = (state, value) => {
	state.primeAuth = new PrimeAuthVO(value);
	localStorage.setItem(PrimeAuthVO.LS_NAME, PrimeAuthVO.encode(state.primeAuth));
};

export const setUser = (state, value) => {
	state.user = new UserVO(value);
};

export const setCatalogId = (state, value) => {
	state.catalogId = value;
};

export const setClientId = (state, value) => {
	state.clientId = value;
};

export const setBrand = (state, value) => {
	state.brand = value ? new BrandVO(value) : null;
};

export const setFranchise = (state, value) => {
	state.franchise = value ? new FranchiseVO(value) : null;
};

export const setDisclaimer = (state, value) => {
	if (value) {
		const remoteDisclaimer = new DisclaimerVO(value);
		const strJSON = localStorage.getItem(DisclaimerVO.SO_NAME);
		if (!strJSON) {
			state.disclaimer = remoteDisclaimer;
			localStorage.setItem(DisclaimerVO.SO_NAME, remoteDisclaimer.strJSON);
		} else {
			const localDisclaimer = new DisclaimerVO(null, strJSON);
			if (localDisclaimer.compare(remoteDisclaimer)) {
				state.disclaimer = localDisclaimer.disclaimerType === DisclaimerVO.Sticky ? localDisclaimer : null;
			} else {
				state.disclaimer = remoteDisclaimer;
				localStorage.setItem(DisclaimerVO.SO_NAME, remoteDisclaimer.strJSON);
			}
		}
	} else {
		state.disclaimer = null;
		localStorage.setItem(DisclaimerVO.SO_NAME, null);
	}
};

export const setSlides = (state, value) => {
	value.forEach((item, idx) => value[idx] = new SlideVO(item));
	value.sort((a, b) => a.order > b.order ? 1 : -1);
	state.slides = value;
};



export const setSkills = (state, value) => {
	value.data.forEach((item, idx) => value.data[idx] = new SkillVO(item, value.included));
	state.skills = state.skills.concat(value.data);
};

export const setLearnings = (state, value) => {
	try {
		const enrollments = {};
		if (value.included) { value.included.forEach(i => enrollments[i.id] = i); }
		const arr = [];
		value.data.forEach((item, idx) => {
			const lvo = new LearningVO(item);
			if (lvo.enrollmentId) {
				lvo.updateEnrollment(enrollments[lvo.enrollmentId]);
				lvo.updateLearnerObjectInstance(enrollments[lvo.instanceId]);
				arr.push(lvo);
			}
		});
		state.learnings = state.learnings.concat(arr);
	} catch (e) {
		console.log(e);
	}
};

export const setLearningStats = (state, value) => {
	for (let i = 0; i < value.data.length; i++) {
		if (value.data[i].attributes) {
			if (value.data[i].attributes.entityType === StatVO.COURSE) {
				state.courseStats = new StatVO(value.data[i].attributes);
			} else if (value.data[i].attributes.entityType === StatVO.LP) {
				state.lpStats = new StatVO(value.data[i].attributes);
			} else if (value.data[i].attributes.entityType === StatVO.CERTIFICATION) {
				state.certificationStats = new StatVO(value.data[i].attributes);
			}
		}
	}
};

export const setRecommended = (state, value) => {
	state.recommended = value.data.map(item => new RecommendedVO(item, value.included)).filter(r => r.loType != null);
};
