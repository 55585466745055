class BrandVO {
  constructor(raw) {
    this.id = null;
    this.color = null;
    this.imageUrl = null;
    this.description = null;

    if (raw) {
      for (const prop in raw) {
        this[prop] = raw[prop]
      }
    }
  }

}

export default BrandVO;
