
export const isDebug = state => state.isDebug;
export const buildVersion = state => state.buildVersion;
export const releaseDate = state => state.releaseDate;
export const accountId = state => state.accountId;

export const gateway = state => state.gateway;
export const basePrimeApi = state => state.basePrimeApi;
export const clientId = state => state.clientId;

export const primeAuth = state => state.primeAuth;
export const user = state => state.user;

export const requiredCatalogId = state => state.requiredCatalogId;
export const catalogId = state => state.catalogId;
export const franchise = state => state.franchise;
export const brand = state => state.brand;
export const disclaimer = state => state.disclaimer;
export const slides = state => state.slides;
export const learnings = state => state.learnings;
export const notifications = state => state.notifications;
export const skills = state => state.skills;
export const recommended = state => state.recommended;

export const courseStats = state => state.courseStats;
export const lpStats = state => state.lpStats;
export const certificationStats = state => state.certificationStats;
