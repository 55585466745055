import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'

Vue.use(VueRouter)

const redirectFromPrime = (to, from, next) => {
	let regex = /^#\/(course|learningProgram|certification)\/\d+$/;
	const learningObjectPath = window.location.hash
	if (regex.test(learningObjectPath)) {
		const basePrimeApi = store.getters['basePrimeApi'];
		const primeAuth = store.getters['primeAuth'];
		window.open(`${basePrimeApi}app/learner?b_qp_custom=false&i_qp_user_id=${primeAuth.user_id}&accountId=${primeAuth.account_id}${learningObjectPath}/overview`,  "_self")
	}
	next();
}

export default new VueRouter({
	scrollBehavior: () => ({ x: 0, y: 0 }),
	routes: [
		{
			path: '/',
			name: 'layout',
			component: () => import('../layouts/MainLayout.vue'),
			beforeEnter: redirectFromPrime,
			children: [
				{
					path: '',
					name: 'home',
					component: () => import('../pages/Dashboard.vue')
				}
			]
		},

		// Always leave this as last one,
		// but you can also remove it
		{
			path: '/',
			component: () => import('../layouts/MainLayout.vue'),
			beforeEnter: redirectFromPrime,
			children: [
			{
				path: '/:catchAll(.*)*',
			},
			],
		},
	]
})
