export function getMessage (basePrimeApi, notification, primeAuth) {
	const { message } = notification;
	const notificationLink = `${basePrimeApi}/app/learner?b_qp_custom=false&i_qp_user_id=${primeAuth.user_id}&accountId=${primeAuth.account_id}#/notifications`;
	let formattedMessage = '';
	const { modelNames } = notification;
	if (modelNames && modelNames.length) {
		if (modelNames.length > 1) {
			let temp = message;
			if (notification.channel.toLowerCase().includes('feedback')) {
				return '';
			} else if (notification.channel === 'course::learnerNominationRequest') {
				modelNames.forEach((modelName, index) => {
					const replacingText =
						index + 1 < modelNames.length
							? `<span>${modelName}</span> `
							: ` <a href="${notificationLink}">${modelName}</a>`;
					temp = temp.replace(defineReplacer(message, index), replacingText);
				});
			} else if (notification.channel === 'course::sessionReminder') {
				modelNames.forEach((modelName, index) => {
					const replacingText =
						index + 1 < modelNames.length ? ` <span>${modelName}</span>` : `${modelName}`;
					temp = temp.replace(defineReplacer(message, index), replacingText);
				});
			} else {
				modelNames.forEach((modelName, index) => {
					temp = temp.replace(defineReplacer(message, index), modelName);
				});
			}
			formattedMessage = `${temp}.`;
		} else {
			const courseName = notification.modelNames[0] ? ` <span>${notification.modelNames[0]}</span>` : '';
			const nameCode = message.includes('{{{name0}}}') ? '{{{name0}}}' : '{{name0}}';
			formattedMessage = `${String(message.split(nameCode)[0] + courseName).trim()}.`;
		}
		return formattedMessage;
	}
	return '';

	function defineReplacer (text, index) {
		if (text.includes('{{{')) {
			return `{{{name${index}}}}`;
		}
		return `{{name${index}}}`;
	}
}
