class DisclaimerVO {

  static get SO_NAME() {
    return "DOMINO_DISCLAIMER_VO";
  }

  static get OneTime() {
    return "OneTime";
  }

  static get Sticky() {
    return "Sticky";
  }

  constructor(raw, strJSON) {
    this.description = null;
    this.disclaimerType = null;

    if (strJSON) {
      raw = JSON.parse(strJSON);
    }

    if (raw) {
      for (const prop in raw) {
        this[prop] = raw[prop]
      }
    }
  }

  get isSticky() {
    return this.disclaimerType === DisclaimerVO.Sticky;
  }

  get strJSON() {
    return JSON.stringify(this);
  }

  compare(disclaimer) {
    return this.description === disclaimer.description && this.disclaimerType === disclaimer.disclaimerType;
  }

}

export default DisclaimerVO;
