class RecommendedVO {
  constructor(raw, details) {
    this.id = null;
    this.reason = null;
    this.name = null;
    this.type = null;
    this.imageUrl = null;
    this.loType = null;
    this.duration = null;

    if (raw && details) {
      try {
        this.id = raw.relationships.learningObject.data.id;
        this.reason = raw.attributes.reason[0];
        const t = this.id.split(":")[0];
        if (t === "course") {
          this.type = "Course";
        } else if (t === "learningProgram") {
          this.type = "Learning Program";
        } else {
          this.type = t;
        }
        for (let i = 0; i < details.length; i++) {
          if (details[i].id === this.id) {
            this.name = details[i].attributes.localizedMetadata[0].name;
            this.imageUrl = details[i].attributes.imageUrl;
            this.loType = details[i].attributes.loType;
            this.duration = details[i].attributes.duration;
          }
        }
      } catch (e) {
        console.log("Error of parsing RecommendedVO ", raw);
      }
    }
  }

  update(raw) {
    if (raw) {
      try {
        this.name = raw.attributes.localizedMetadata[0].name;
      } catch (e) {
        console.log("Error of updating RecommendedVO ", raw);
      }
    }
  }

}

export default RecommendedVO;

