<template>
	<router-view v-if="isInitialized" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PrimeAuthVO from './vo/PrimeAuthVO';
import qs from 'qs';

export default {
	name: 'app',
	data () {
		return {
			isInitialized: false,
			errorMsg: ''
		};
	},
	methods: {
		...mapActions(['getBrand', 'getPrimeData', 'refreshPrimeData', 'getUser', 'getFranchise', 'getCatalogs']),
		authorize () {
			const u = `${this.basePrimeApi}oauth/o/authorize?client_id=${this.clientId}&redirect_uri=${window.location.origin}&scope=learner:read,learner:write&response_type=CODE&loginUrl=%2F${this.accountId}`;
			window.open(u, '_self');
		},
		loadFranchise () {
			this.getFranchise().then(data => {
				if (this.franchise) {
					this.loadCatalogs();
				} else {
					// todo: no franchise! what we need to do?
				}
			});
		},
		loadCatalogs (next) {
			this.getCatalogs(next).then(nextLink => {
				if (this.catalogId) {
					this.loadBrand();
				} else if (nextLink) {
					this.loadCatalogs(nextLink);
				} else {
					// TODO: no more catalogs. what we eed to do?
				}
			});
		},
		loadBrand () {
			this.getBrand().then(hasBrand => {
				this.isInitialized = true;
				if (!this.user.id) {
					this.getUser();
				}
			});
		}
	},
	computed: {
		...mapGetters(['accountId', 'clientId', 'basePrimeApi', 'user', 'franchise', 'catalogId'])
	},
	created () {
		const location = window.location;
		const queryData = qs.parse(location.search.replace('?', ''));
		if (window.location.href.indexOf('ERROR=CLIENT_UNAPPROVED') !== -1) {
			this.errorMsg = 'We are not able to obtain your identity from Prime.<br/><span style="color:red;">Error Message: Client_Unapproved.</span><br/>Please log out from all Prime accounts and retry.<br/>Please contact support if you still see this message.';
			return;
		}
		if (localStorage.getItem(PrimeAuthVO.LS_NAME)) {
			const auth = PrimeAuthVO.decode(localStorage.getItem(PrimeAuthVO.LS_NAME));
			this.refreshPrimeData(auth.refresh_token).then(data => {
				if (data && data.isSuccess) {
					this.loadFranchise();
				} else {
					if (data) {
						console.log(data.message);
					}
					console.log('Removing old authentication data...');
					localStorage.removeItem(PrimeAuthVO.LS_NAME);
					this.authorize();
				}
			});
		} else {
			if (!queryData.code) {
				this.authorize();
			} else {
				this.getPrimeData(queryData.code).then(data => {
					setTimeout(function () {
						window.location = location.origin;
					}, 500);
				});
			}
		}
	}
};
</script>

<style src="./styles/main.css">

</style>
