class PrimeAuthVO {
	constructor (raw) {
		this.account_id = null
		this.access_token = null
		this.refresh_token = null
		this.user_id = null
		this.user_role = null
		this.expiresIn = null

		if (raw) {
			for (const prop in raw) {
				this[prop] = raw[prop]
			}
		}
	}

	static LS_NAME = 'primeAuth'

	static encode (raw) {
		return JSON.stringify(raw)
	}

	static decode (raw) {
		return new PrimeAuthVO(JSON.parse(raw))
	}
}

export default PrimeAuthVO
