import axios from 'axios';

import NotificationVO from '../../vo/NotificationVO';
import { getMessage } from '../../helpers';

const state = {
	notifications: []
};

const actions = {
	/**
	 * Loads notifications
	 * @param {Context} context - is set by vuex
	 * @returns full VO data, i.e. {data, message, isSuccess}
	 */
	async getNotifications ({ commit, rootState, state }) {
		// PBL-14: We need to get 6 notifications,
		// but some of the can be broken
		const limit = 10;
		const URLBase = `/users/${rootState.primeAuth.user_id}/userNotifications?page[limit]=${limit}`;
		try {
			const unread = await axios.get(`${URLBase}&read=false`);
			const read = await axios.get(`${URLBase}&read=true`);
			if (unread.data && unread.data.data && read.data && read.data.data) {
				const notifications = [...unread.data.data, ...read.data.data];
				commit('setNotifications', notifications);
			}
			return true;
		} catch (e) {
			if (state.isDebug) {
				console.log(e);
			}
		}
	},
	/**
	 * Update notifications
	 * @param {Context} context - is set by vuex
	 * @returns full VO data, i.e. {data, message, isSuccess}
	 */
	async updateNotification ({ dispatch, commit, state, rootState }, notifications) {
		try {
			await notifications.forEach(async notification => {
				await axios.patch(
					`/users/${rootState.primeAuth.user_id}/userNotifications/${notification.id}`,
					{
						data: {
							id: notification.id,
							type: notification.type,
							attributes: {
								...notification,
								actionTaken: true,
								read: true
							}
						}
					},
					{
						headers: {
							'Content-Type': 'application/vnd.api+json;charset=UTF-8'
						}
					}
				);
			});
		} catch (e) {
			if (state.isDebug) {
				console.log(e);
			}
		}
	}
};

const mutations = {
	setNotifications (state, value) {
		value.forEach((item, idx) => value[idx] = new NotificationVO(item));
		value.sort((a, b) => a.dateCreated < b.dateCreated ? 1 : -1);
		state.notifications = value;
	}
};

const getters = {
	formattedNotifications (state, getters, rootState) {
		const limit = 10;
		return state.notifications
			.filter(notification => {
				return getMessage(rootState.basePrimeApi, notification, rootState.primeAuth);
			})
			.splice(0, limit);
	},
	unreadNotifications (state, getters) {
		return getters.formattedNotifications.reduce((acc, notification) => {
			if (!notification.read) {
				acc.push(notification);
			}
			return acc;
		}, []);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
