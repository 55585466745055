import Vuex from 'vuex';
import Vue from 'vue';

import UserVO from '../vo/UserVO';

import * as actions from './actions';
import notifications from './modules/notifications';
import * as mutations from './mutations';
import * as getters from './getters';
import BrandVO from '../vo/BrandVO';
import DisclaimerVO from '../vo/DisclamerVO';
import FranchiseVO from '../vo/FranchiseVO';

Vue.use(Vuex);

export const appConfig = {
	accountId: 0,
	buildVersion: process.env.VERSION,
	isDebug: process.env.DEBUG_MODE,
	releaseDate: process.env.RELEASE_DATE,
	primeHasSSO: false
};

export default new Vuex.Store({
	modules: {
		notifications
	},
	state: {
		...appConfig,
		basePrimeApi: 'https://captivateprime.adobe.com/',
		INIT_PATH_SO_NAME: 'initialPath',
		clientId: '',
		gateway: '',
		requiredCatalogId: '',

		primeAuth: null,
		user: new UserVO(),

		catalogId: null,
		brand: new BrandVO(),
		franchise: new FranchiseVO(),
		disclaimer: new DisclaimerVO(),
		slides: [],
		learnings: [],
		skills: [],
		recommended: [],

		courseStats: {},
		lpStats: {},
		certificationStats: {}
	},
	getters,
	mutations,
	actions
});
