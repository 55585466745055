import axios from 'axios';
import PrimeAuthVO from '../vo/PrimeAuthVO';
import moment from 'moment';

function setDefaultHeaders (context) {
	axios.defaults.baseURL = context.state.basePrimeApi + 'primeapi/v2';
	axios.defaults.headers.Authorization = 'oauth ' + context.state.primeAuth.access_token;
}

export const getPrimeData = async (context, code) => {
	try {
		let data = await axios.post(context.state.gateway + 'prime-auth-api', { code: code });
		data = data.data;
		if (data.isSuccess) {
			context.commit('setPrimeAuth', data.data);
			setDefaultHeaders(context);
		}
		return data.data;
	} catch (e) {
		return e.data;
	}
};

export const refreshPrimeData = ({ state, commit, dispatch }, code) => {
	return axios.post(state.gateway + 'prime-auth-api/refresh', { code: code }).then(response => {
		if (response.data && response.data.isSuccess) {
			commit('setPrimeAuth', response.data.data);
			if (state.primeAuth) {
				localStorage.setItem(state.INIT_PATH_SO_NAME, window.location.hash);
				if (state.primeAuth.user_role === 'learner') {
					setDefaultHeaders({ state });
					if (localStorage.getItem(state.INIT_PATH_SO_NAME)) {
						window.location.href =
							window.location.origin + localStorage.getItem(state.INIT_PATH_SO_NAME);
					}
				} else {
					localStorage.removeItem(PrimeAuthVO.LS_NAME);
					dispatch('redirectToLogin');
				}
			}
		}
		return response.data;
	}, () => {
		dispatch('redirectToLogin');
	});
};

export const redirectToLogin = ({ state }) => {
	localStorage.setItem(state.INIT_PATH_SO_NAME, window.location.hash);

	window.open(
		state.basePrimeApi +
		'oauth/o/authorize?client_id=' +
		state.clientId +
		'&redirect_uri=' +
		location.origin +
		'&scope=learner:read,learner:write&response_type=CODE',
		'_self'
	);
};

export const getUser = async (context) => {
	try {
		let data = await axios.get('users/' + context.state.primeAuth.user_id);
		data = data.data;
		if (data.data) {
			context.commit('setUser', data.data);
		}
		return data;
	} catch (e) {
		if (context.state.isDebug) {
			console.log(e);
		}
	}
};

export const doLogout = (context) => {
	localStorage.removeItem(PrimeAuthVO.LS_NAME);

	window.open(context.state.basePrimeApi + `logout?accountId=${context.state.primeAuth.account_id}&userId=${context.state.user_id}`, '_self');
};

export const changeRole = ({state}, role) => {
	role = role === 'Role Config Role' ? 'admin' : role.toLowerCase().replace(/\s/g, '');

	if (state.primeAuth)
		window.open(state.basePrimeApi + 'app/' + role + '?b_qp_custom=false&i_qp_user_id=' + state.primeAuth.user_id + '&accountId=' + state.primeAuth.account_id, '_self');
};

/**
 * Gets franchise data for current user
 * @param {Context} context - is set by vuex
 * @returns {Boolean} isSuccess
 */
export const getFranchise = async (context) => {
	try {
		let data = await axios.get(context.state.gateway + 'api/account/me' + '?ts=' + new Date().getTime(), {
			headers: {
				Authorization: `oauth ${context.state.primeAuth.access_token}`
			}
		});
		data = data.data;
		if (data.isSuccess) {
			context.commit('setFranchise', data.data);
		}
		return data.isSuccess;
	} catch (e) {
		if (context.state.isDebug) {
			console.log(e);
		}
	}
};

/**
 * Gets brand data for current user
 * @param {Context} context - is set by vuex
 * @returns {Boolean} isSuccess
 */
export const getBrand = async (context) => {
	try {
		let data = await axios.get(context.state.gateway + 'api/brands/current' + '?ts=' + new Date().getTime(), {
			headers: {
				Authorization: `oauth ${context.state.primeAuth.access_token}`
			}
		});
		data = data.data;
		if (data.isSuccess) {
			context.commit('setBrand', data.data);
		}
		return data.isSuccess;
	} catch (e) {
		if (context.state.isDebug) {
			console.log(e);
		}
	}
};

/**
 * Gets disclaimer data for current user
 * @param {Context} context - is set by vuex
 * @returns {Boolean} isSuccess
 */
export const getDisclaimer = async (context) => {
	try {
		let data = await axios.get(context.state.gateway + 'api/disclaimers/current' + '?ts=' + new Date().getTime(), {
			headers: {
				Authorization: `oauth ${context.state.primeAuth.access_token}`
			}
		});
		data = data.data;
		if (data.isSuccess) {
			context.commit('setDisclaimer', data.data);
		}
		return data.isSuccess;
	} catch (e) {
		if (context.state.isDebug) {
			console.log(e);
		}
	}
};

/**
 * Loads all slides from the custom API (CMS)
 * @param {Context} context - is set by vuex
 * @returns {Boolean} isSuccess
 */
export const getSlides = async (context) => {
	try {
		let data = await axios.get(context.state.gateway + 'api/slides' + '?ts=' + new Date().getTime());
		data = data.data;
		if (data.isSuccess) {
			context.commit('setSlides', data.data);
		}
		return data.isSuccess;
	} catch (e) {
		if (context.state.isDebug) {
			console.log(e);
		}
	}
};

/**
 * Loads catalogs to find the one for franchise
 * @param {Context} context - is set by vuex
 * @param next - next link if exists
 * @returns {String} link to next chunk
 */
export const getCatalogs = async (context, next) => {
	try {
		const data = await axios.get(next || 'catalogs?page[limit]=10');
		const arr = data.data ? data.data.data : [];
		const f = context.state.franchise.franchise.toLowerCase();
		if (arr.length) {
			arr.forEach(c => {
				if (f.toLocaleLowerCase().indexOf(c.attributes.name.toLocaleLowerCase()) >= 0) {
					context.commit('setCatalogId', c.id);
				}
			});
			return data.data.links.next;
		}
		return data;
	} catch (e) {
		if (context.state.isDebug) {
			console.log(e);
		}
	}
};

/**
 * Loads users' skills
 * @param {Context} context - is set by vuex
 * @returns {String} link to next chunk
 */
export const getMySkills = async (context) => {
	try {
		let link = `users/${context.state.primeAuth.user_id}/userSkills?include=skillLevel,skillLevel.skill&page[limit]=10`
		while (link) {
	            let data = await axios.get(link);
				data = data.data;
	            context.commit("setSkills", data);
				link = data.links ? data.links.next : null;
	        }
		return true;
	} catch (e) {
		if (context.state.isDebug) {
			console.log(e);
		}
	}
};

/**
 *  Loads all courses (except completed) for current user
 * @param {Context} context - is set by vuex
 * @param {String} nextLink - link to load the next chunk of data if exists
 * @returns full VO data, i.e. {data, message, isSuccess}
 */
export const getMyLearning = async (context, nextLink) => {
	try {
		let data = await axios.get(nextLink || `/learningObjects?include=instances&page[limit]=10&page[cursor]=0&filter.catalogIds=${context.state.catalogId},${context.state.requiredCatalogId}&filter.loTypes=course,learningProgram,certification&filter.learnerState=enrolled,started&sort=date`);
		data = data.data;
		if (data.data) {
			context.commit('setLearnings', data);
		}
		return data;
	} catch (e) {
		if (context.state.isDebug) {
			console.log(e);
		}
	}
};
/**
 *  Loads missed courses (except completed) for current user
 * @param {Context} context - is set by vuex
 * @param {String} ids - an array of learningObject Ids
 * @returns full VO data, i.e. {data, message, isSuccess}
 */
export const getMyMissedLearning = async (context, ids) => {
	try {
		let data = await axios.get(`/learningObjects?include=instances&page[limit]=10&page[cursor]=0&filter.loTypes=course,learningProgram,certification&filter.learnerState=enrolled,started&sort=date&ids=${ids}`);
		data = data.data;
		if (data.data) {
			context.commit('setLearnings', data);
		}
		return data;
	} catch (e) {
		if (context.state.isDebug) {
			console.log(e);
		}
	}
};
/**
 *  is used to load stats info for the learning objects (type, status)
 * @param {Context} context - is set by vuex
 * @returns full VO data, i.e. {data, message, isSuccess}
 */
export const getLearningStats = async (context) => {
	try {
		const adobeStandard = 'YYYY-MM-DDThh:mm:ssZ';
		let data = await axios.get('stats?clientDateTime=' + moment().format(adobeStandard));
		data = data.data;
		if (data.data) {
			context.commit('setLearningStats', data);
		}
		return data;
	} catch (e) {
		if (context.state.isDebug) {
			console.log(e);
		}
	}
};

export const getCatalogContentEnrollment = async ({ state }, content) => {
	try {
		const data = await axios.get(`enrollments/${content.enrollmentId}?include=loResourceGrades&ts=${new Date().getTime()}`);
		if (data.data && data.data.data) { content.updateEnrollment(data.data.data); }
		if (data.data && data.data.included && content.modules.length) {
			data.data.included.forEach(enrollment => {
				content.modules.forEach(module => {
					if (enrollment.id.indexOf(module.id) >= 0) { module.updateEnrollment(enrollment); }
				});
			});
		}
		return true;
	} catch (e) {
		return false;
	}
};
/**
 * Gets a list (up to 8) recommended learnings for current user       ```
 * @param {Context} context - is set by vuex
 * @returns full VO data, i.e. {data, message, isSuccess}
 */
export const getRecommended = async (context) => {
	try {
		let data = await axios.get('/recommendations?page[offset]=0&page[limit]=8&filter.loTypes=course%2ClearningProgram%2Ccertification&include=learningObject');
		data = data.data;
		if (data.data) {
			context.commit('setRecommended', data);
		}
		return data;
	} catch (e) {
		if (context.state.isDebug) {
			console.log(e);
		}
	}
};

/**
 * Gets a list (up to 5) jobs for current user
 * @param {Context} context - is set by vuex
 * @returns full VO data, i.e. {data, message, isSuccess}
 */
export const getJobs = async (context) => {
	try {
		let data = await axios.get('/jobs');
		data = data.data;
		if (data.data) {
			// context.commit("setJobs", data);
		}
		return data;
	} catch (e) {
		if (context.state.isDebug) {
			console.log(e);
		}
	}
};
