class StatVO {
  static COURSE = "course";
  static LP = "lp";
  static CERTIFICATION = "certification";

  constructor( raw ) {
    this.entityType = null;

    this.completed = [];
    this.due = [];
    this.overdue = [];
    this.safe = [];

    this.ids = [];

    try {
      this.entityType = raw.entityType;
      this.completed = raw.completed.length ? raw.completed : [];
      this.due = raw.due.length ? raw.due : [];
      this.overdue = raw.overdue.length ? raw.overdue : [];
      this.safe = raw.safe.length ? raw.safe : [];

      this.ids = this.completed.concat(this.due).concat(this.overdue).concat(this.safe);
    } catch( e ) {
      console.log( "Stats parsing error", e );
    }
  }

}

export default StatVO;
