class SkillVO {
  constructor(raw, rls) {
    this.id = null;
    this.skillLevelId = 0;

    this.pointsEarned = 0;

    this.name = null;
    this.state = null;

    this.level = 0;
    this.maxCredits = 0;
    this.levelName = null;
    this.percentCompleted = 0;

    if (raw) {
      try {
        this.id = raw.id;
        const slid = raw.id.split("_")[1];
        this.pointsEarned = raw.attributes.pointsEarned;
        this.skillLevelId = raw.relationships.skillLevel.data.id;

        if (rls) {
          const skill = rls.find(item => item.id === slid);
          const skillLevel = rls.find(item => item.id === this.skillLevelId);
          if (skill && skillLevel) {
            this.name = skill.attributes.name;
            this.state = skill.attributes.state;

            this.level = skillLevel.attributes.level;
            this.maxCredits = skillLevel.attributes.maxCredits;
            this.levelName = skillLevel.attributes.name;

            if (this.maxCredits) {
              var pc = parseInt((this.pointsEarned / this.maxCredits) * 100);
              this.percentCompleted = pc >= 100 ? 100 : pc;
            }
          }
        }
      } catch (e) {
        console.log("Error of parsing skill object");
      }

    }
  }

}

export default SkillVO;
